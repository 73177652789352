.search-page {
  position: relative;
  width: 100%;
  height: calc(100vh - #{$headerLargeSize});

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  margin: 0 auto;
  min-height: 875px; // Keeps footer from conflicting with content
  //background-color: #ff000033; // Debug

  //////////////////////////////////
  // Used to capture click out of field
  .search-page-whole {
    position: absolute;
    width: 100%;
    height: calc(100vh);
    margin-top: -150px;
    z-index: 0;
    //background-color: #ff000033; // Debug
  }

  //////////////////////////////////
  // Layout helpers
  .spacing {
    margin: 0 10px 30px 10px;
    position: relative;
    //background-color: #ffff0033; // Debug
  }
  .search-content-size {
    width: 100%;
    min-width: 1000px;
    max-width: $contentSize;
    margin: 0 auto;
  }
  .search-error-msg {
    color: $flagColor;
  }

  //////////////////////////////////
  // Top Search Field
  .search-field {
    justify-self: center;
    align-self: center;
    align-items: center;

    width: 100%;
    min-width: 1000px;
    max-width: $contentSize;
    margin: 0 auto;
  }

  //////////////////////////////////
  // Dropzone control
  .search-dropzone-container {
    margin-top: -20px;
    justify-self: center;
    align-self: center;
    align-items: center;

    .dropzone {
      width: 100%;
      height: 200px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-width: 2px;
      border-radius: 2px;
      border-color: $edgeColor;
      border-style: dashed;
      background-color: $secondaryBgColor;
      color: $secondaryBgColor;
      outline: none;
      cursor: pointer;
      transition: border 0.24s;
    }
  }

  //////////////////////////////////
  // JSME control
  .search-field-jsme {
    margin-top: -20px;
    justify-self: center;
    align-self: center;
    align-items: center;
  }

  //////////////////////////////////
  // Search notification
  .search-notification-container {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $popupBgColor;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    .search-notification {
      color: $primaryBgColor;
      text-align: center;
      z-index: 1;
    }
  }

  //////////////////////////////////
  // Search Options section
  .search-options-container {
    width: 100%;
    max-width: $contentSize;
    justify-self: center;
    align-self: center;

    .search-options-error {
      color: $flagColor;
    }

    .search-options-row {
      display: flex;
      justify-content: space-between;
      label {
        font-size: $primaryBodyFontSize;
      }
      .column {
        padding-right: 10px;
        margin-right: 20px;
        padding-bottom: 5px;
      }
    }
  }

  //////////////////////////////////
  // Recent saved search subsection
  .recent-search-content {
    background-color: $secondaryBgColor;
    flex-grow: 1;

    .search-saved-routes {
      position: relative;
      width: 100%;
      max-width: $contentSize;
      //height: 100%;
      //min-height: 300px;
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;

      // Individual tiles for recent saved routes
      .recent-search-canvas-container {
        position: relative;
        background-color: $primaryBgColor;
        flex: 1;
        margin: 5px;
        border: 2px solid transparent;
        height: 225px; // Force height
        cursor: pointer;
        &:hover {
          border-color: $spotColor;
        }

        // For proxy saved routes
        &.proxy {
          background-color: $secondaryBgColor;
          border: 2px dashed $placeholderColor;
          text-align: center;
          .recent-search-canvas {
            position: relative;
            background-color: $secondaryBgColor;
            flex: 1;
            margin: 5px;
            padding-top: 10px;
            height: 100%;
	    width: 300px; /*100%;*/
          }
          p {
            font-size: $primaryBodyFontSize;
            color: $placeholderColor;
          }
          &:hover {
            border-color: $placeholderColor;
            cursor: default;
          }
        }

        .recent-search-canvas-timestamp {
          margin: 10px 0 0 10px;
          height: 20px;
          font-size: $smallBodyFontSize;
          align-self: start;
        }
      }
    }
  }
}
