/**
 * Variables used in scss and styled-components
 * Access it in styled-components by using theme.  (ex. ${props => $props.theme.<variable below> })
 */

/* 
 * WARNING: when modifying this, also update CSS rules in `src/containers/App/App.js`
 * This is due to complications loading this file into a JavaScript object in App.js. 
 */

// sizes
$sizeXsmall: 5px;
$sizeSmall: 15px;
$sizeMedium: 30px;
$sizeLarge: 50px;

$headerSize: 75px;
$headerLargeSize: 150px;
$footerSize: 75px;
$contentSize: 1200px;

$borderRadius: 4px;

// New color standards
$debugColor: #ff0000;
$primaryColor: #191d1e;
$secondaryColor: #808080;
$tertiaryColor: #2c4652; // darkBlue
$edgeColor: #979797;
$placeholderColor: #9b9b9b;

$spotColor: #46c5e9; // lightBlue
$saveColor: #f8d33b; // yellow
$flagColor: #e40d2b; // red
$keepColor: #284451; //
$primaryBgColor: #ffffff;
$secondaryBgColor: #e7e7e7;
$tertiaryBgColor: #000000;
$popupBgColor: #00000055;
$popupAltBgColor: #ffffff77;

// New size standards
$largeBodyFontSize: 18px;
$primaryBodyFontSize: 16px;
$smallBodyFontSize: 12px;
$primaryButtonFontSize: 12px;

// Button color
$primaryButtonColor: #2c4652; // darkBlue
$primaryButtonHoverColor: #46c5e9; // lightBlue
$disabledButtonColor: #cccccc; // edgeColor 979797
$disabledButtonHoverColor: #cccccc; // edgeColor
$flagButtonHoverColor: #e40d2b; // red
$transparentButtonColor: transparent;
$saveButtonColor: #f8d33b; // TODO: yellow, to be removed?
