.login-page {
  .login-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 15px $tertiaryColor solid;
    height: calc(100vh - #{$footerSize} - 15px);

    // Introduction title and summary
    .login-introduction {
      width: 100%;
      padding-bottom: 75px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      div {
        //width: 600px;
        padding: 0 25px;
        margin: 0;
        h1 {
          background-repeat: no-repeat;
          background-size: 100% 100%;
          text-indent: -9000px;
          width: 395px;
          height: 100px;
          overflow: hidden;
          padding: 0;
          margin: 25px 0;
        }
        p {
          font-size: $largeBodyFontSize;
          color: $primaryColor;
          width: 500px;
        }
      }
    }

    // Login fields
    .login-field {
      width: 100%;
      background-color: $secondaryBgColor;
      height: calc(100vh - #{$footerSize} - 15px);
      display: flex;
      flex-direction: column;
      justify-content: center;

      .login-input-container {
        padding-left: 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .login-input-text {
            margin-top: 20px;
        }
      }
      .login-button-container {
        display: flex;
        justify-content: space-between;
        max-width: 300px;
        margin-top: 25px;
      }
    }
  }

  .okta-login {
    margin-top: 5px;
    margin-left: 75px;
    max-width: 120px;
  }
}
