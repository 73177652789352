.node
{
    display: flex;
    position: absolute;
    border: 3px solid $spotColor;
    justify-content: center;
}

/*.node button,
#BOM-buttons button {
*/
button {
    font-family: $primaryFont;
    background-color: $primaryBgColor;
    padding: 4px 8px;
    cursor: pointer;
    margin: 1px;
    color: $primaryButtonColor;
    text-transform: uppercase;
    font-weight: 400 !important;
    font-size: $primaryButtonFontSize !important;
    white-space: nowrap;
    border: 2px solid $primaryButtonColor;

    &:hover {
	color: $spotColor;
	border-color: $spotColor;
	background-color: transparent;
    }
}

.node.keep {
    border: 3px dotted $keepColor;
}

.keep .btnKeep {
    color: $primaryBgColor;
    border-color: $keepColor;
    background-color: $keepColor;
}

.node.avoid {
    border: 3px dotted $flagColor;
}
.avoid .btnAvoid {
    color: $primaryBgColor;
    border-color: $flagColor;
    background-color: $flagColor;
}

.node.detailed {
    border: 3px solid $secondaryColor !important;
}

.footer {
    position: absolute;
    bottom: 0px;
}

.hide {
    display: none;
}

.nohide {
    display: flex !important;
}

/*  http://www.cssarrowplease.com/ */
.arrow_box_left {
    background: #ccc;
    border: 1px solid #000000;
    border-radius: 5px;
    white-space: nowrap;
}

.arrow_box_left:after,
.arrow_box_left:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box_left:after {
    border-color: rgba(163, 160, 158, 0);
    border-right-color: #a3a09e;
    border-width: 8px;
    margin-top: -8px;
}
.arrow_box_left:before {
    border-color: rgba(0, 0, 0, 0);
    border-right-color: #000000;
    border-width: 9px;
    margin-top: -9px;
}

.tooltip {
    display: inline;
    position: absolute;
    z-index: 6;
}

.hidden {
    visibility: hidden;
}


.popup
{
    position: absolute;
    margin-left: 25px;
    background-color: white;
    border: 3px solid #a3a09e;
    border-radius: 5px;
    z-Index: 10;
    display: none;
}

.popup-title
{
    padding: 8px;
}

.popup .scroller
{
    max-width: 90vw;
    max-height: calc(99vh - 200px);
    width: 100%;
    overflow: scroll;
}

.popup table
{
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}


.popup table td,
.popup table th
{
    border: 1px solid #ddd;
    padding: 8px;
}

.popup table tr:nth-child(even)
{
    background-color: #f8f8f8;
}

.popup table tr:hover
{
    background-color: #ddd;
}

.popup table th
{
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #e7e7e7;
    color: black;
}

.popup table th,
.popup table td
{
    text-align: right;
}

#BOM-table td:nth-child(1),	/*compound*/
#BOM-table th:nth-child(1),	
#BOM-table td:nth-child(3),	/*supplier*/
#BOM-table th:nth-child(3),
#BOM-table td:nth-child(6),	/*Units*/
#BOM-table th:nth-child(6)
		       {
			   text-align: left;
		       }

.popup .buttons
{
    float:right;
}

.popup button
{
    border: 0px;
}

.popup button:focus,
.node button:focus {
    outline-style: none;
}

.purchaseBtn {
    position: absolute;
    width: 25px;
}

.purchasable {
    cursor: pointer;
    z-index: 1;
}
/*
#SMARTS-table td,
#SMARTS-table th
{
    border: 1px solid #ddd;
    padding: 8px;
}

#SMARTS-table tr:nth-child(even)
{
    background-color: #f8f8f8;
}

#SMARTS-table tr:hover
{
    background-color: #ddd;
}

#SMARTS-table th
{
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #e7e7e7;
    color: black;
}

#smartsTip
{
    width: 90vw;
    max-height: 90vh;
    border: 1px solid #000;
    border-radius: 5px;
    overflow: auto;
}
*/
.smartsPreview
{
    background-color: #fff;
    padding: 10px 1px;
}

#Procedure-div
{
  margin: 8px;
}

.popup-title
{
  background-color: #ccc;
}