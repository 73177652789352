.user-page {
  position: relative;
  width: 100%;
  min-height: calc(100vh - #{$headerSize});
  background-color: $secondaryBgColor;
  display: flex;
  justify-content: center;

  .user-contents {
    width: $contentSize;
    display: flex;
    align-content: center;
    z-index: 1;
  }
  .user-header-text {
    padding-top: 65px;
    padding-bottom: 25px;
  }

  // User information area, sidebar
  .user-information {
    width: 300px;
    margin: 25px 10px 0 0;
    padding-left: 10px;

    h2 {
      text-align: center;
      font-size: $largeBodyFontSize;
      padding: 10px 0;
      margin: 0;
    }
    h3 {
      text-align: center;
      font-size: $primaryBodyFontSize;
      font-weight: 400;
      padding: 0;
      margin: 0;
    }
    .nextSection {
      padding: 25px 0;
      p {
        text-align: center;
        font-size: $primaryBodyFontSize;
        font-weight: 400;
        padding: 5px 0;
        margin: 0;
      }
    }
    .controls {
      display: flex;
      justify-content: flex-end;
      padding: 0 !important;
      margin: 0 !important;
      width: 100%;
      button {
        margin: 0 20px 0 0;
      }
    }
    .controls.center {
      display: flex;
      justify-content: center;
      padding: 0 !important;
      margin: 0 !important;
      width: 100%;
      button {
        margin: 0 !important;
      }
    }
  }

  .user-popup {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $popupBgColor;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }

  .user-right {
    margin-top: 10px;
    width: 100%;
    overflow-y: auto;
    height: calc(100vh - (#{$headerSize} + 10px));
  }

  .user-route-top {
    background-color: $primaryBgColor;
    padding: 20px 30px;
    margin-bottom: 25px;
  }

  .user-saved-routes {
    display: flex;
    flex-direction: column;
  }
}
