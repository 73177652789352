.route-page {
  width: 100%;
  min-height: calc(100vh - #{$headerSize} - #{$footerSize});
  background-color: $secondaryBgColor;
  display: flex;
  flex-direction: column;

  .search-notification-container {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: $popupBgColor;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  .route-arrow-container {
    display: flex;
    margin-right: -7px;
    //width: 100px;
  }

  .route-border {
    margin: 15px 0;
    border: 1px solid $secondaryColor;
  }

  .route-contents {
    display: flex;
    width: 100%;
    background-color: $secondaryBgColor;
  }

  .route-contents-print {
    //display: block;
    display: flex;
    width: 1000px;
    background-color: $secondaryBgColor;
  }

  .route-jsme {
    //height: 40px;
  }

  .route-header {
    height: 50px;
    background-color: $secondaryBgColor;
    color: $primaryColor;
    padding: 0 10px;
    position: relative;

    .route-header-left {
      height: 50px;
      display: flex;
      align-items: center;
      h3 {
        margin: 0 10px 0 0;
      }
    }

    .route-header-right {
      display: flex;
      align-items: flex-end;
    }
  }

  // Left column: Shows and hides based on user control
  .route-left {
    min-width: 300px;
    margin-right: 10px;
    margin-left: 10px;
    background-color: $secondaryBgColor;

    .route-search-options {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }

    .route-search-button-container {
      margin-top: 25px;
    }
  }

  .route-left-collapsed {
    width: 30px;

    .route-search-button-container-min {
      display: flex;
      flex-direction: column;
    }
  }

  // Right area: Main canvas area for routes
  .route-right {
    width: 100%;
    height: calc(100vh - #{$headerSize} - #{$footerSize});
    overflow-y: auto;
    background-color: $primaryBgColor;
  }

  .route-route-top {
    padding: 20px 30px;
    margin-bottom: 25px;
  }

  .route-options-error {
    color: $flagColor;
  }

  @media print {
    background-color: $primaryBgColor !important;
    .route-search-button-container {
      display: none;
    }
    .route-left {
      width: 8cm;
      background-color: $primaryBgColor;
      margin: 0;
      padding-right: 1cm;
      border-right: 1px solid $primaryColor;
    }
  }
}
