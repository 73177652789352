.strategy-page {
  width: 100%;
  min-height: calc(100vh - #{$headerSize});
  background-color: $secondaryBgColor;
  display: flex;

  .search-notification-container {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .strategy-contents {
    display: flex;
    width: 100%;
  }

  .strategy-jsme {
  }

  .strategy-left {
    width: 300px;
    margin-right: 10px;
    margin-left: 10px;
    .strategies-search-options {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      height: 400px;
    }

    .result-search-button-container {
      margin-top: 70px;
      float: right;
    }
  }

  .strategy-right {
    padding-right: 10px;
    height: calc(100vh - #{$headerSize});
    overflow-y: auto;
    flex: 1;
  }

  .strategy-route-label {
    display: flex;
    //justify-content: center;
    align-items: flex-start;
    font-size: 1.17em;
    strong {
      white-space: nowrap;
      margin-right: 25px;
    }
  }

  .strategy-route-top {
    background-color: $primaryBgColor;
    padding: 10px 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .strategy-options-error {
    color: $flagColor;
  }
}
