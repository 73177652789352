.strategies-page {
  width: 100%;
  min-height: calc(100vh - #{$headerSize} - #{$footerSize});
  //max-height: calc(100vh - #{$headerSize});
  background-color: $secondaryBgColor;
  display: flex;

  .search-notification-container {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $popupBgColor;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .strategies-contents {
    display: flex;
    width: 100%;
  }

  .strategies-jsme {
  }

  .strategies-left {
    width: 300px;
    margin-right: 10px;
    margin-left: 10px;
    .strategies-search-options {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      height: 400px;
    }

    .result-search-button-container {
      margin-top: 70px;
      float: right;
    }
  }

  .strategies-right {
    padding-top: 10px;
    padding-right: 10px;
    height: calc(100vh - #{$headerSize});
    overflow-y: auto;
    flex: 1;

    .strategies-result-msg {
      color: $flagColor;
    }
  }

  .strategies-route-top {
    background-color: $primaryBgColor;
    padding: 20px 30px;
    margin-bottom: 10px;
  }

  .strategies-options-error {
    color: $flagColor;
  }
}
