html,
body,
#root,
.App {
  height: 100vh;
  min-width: 900px;
}

body {
  margin: 0;
  font-family: $primaryFont;
  box-sizing: border-box;
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact;
  font-weight: 400;
}

h3 {
  font-weight: 700;
  font-size: $primaryBodyFontSize;
  color: $primaryColor;
  margin: 10px 0;
  padding: 5px 0;
}

p {
  font-weight: 400;
  font-size: $primaryBodyFontSize;
  color: $primaryColor;
}

.flex-row-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// remove blue outline in chrome
*:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

button {
  font: inherit !important;
}

// remove increment arrows from input number
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// override react tab styles
.react-tabs__tab-list {
  border-bottom: none;
}

.react-tabs__tab--selected {
  border-bottom: 2px black solid !important;
  background: inherit;
}

.react-tabs__tab {
  border: none;
}

.react-tabs__tab:focus:after {
  background-color: $tertiaryBgColor;
}

p.hint {
  font-weight: 400;
  color: $secondaryColor;
  font-size: $smallBodyFontSize;
  font-style: italic;
}

//set textarea font
textarea {
  font-family: $primaryFont;
}

// Centralized modal-controls deck
.modal-controls {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  margin-top: 5px;
  padding: 0;
  width: 100%;
  button {
    margin: 0 0;
    &.left {
      margin: 0 5px 0 0;
      padding: 0;
    }
  }
}
